import React, { Component } from 'react';
import {Link, NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import logo from './logo.webp';

const Footer = () => {

    return(
        <>

        <div className="footer-area hrrd-footer-area positioning pb-3">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                        <div className="footer-wrap">
                            <div className="row ">
                                <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                    <div className="single-widget">
                                        <div className="footer-logo">
                                            <Link to="/"><img src={logo}
                                                              alt="apls web development" /></Link>
                                            <p className="footer-color">Start a project now and be a part of our magnificent aPLS family.</p>
                                            <div className="footer-social wow animate flipInX" data-wow-delay="200ms"
                                                 data-wow-duration="1500ms">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/aplswd"><i
                                                        className="bi bi-facebook"></i></a></li>
                                                    <li><a href="https://www.linkedin.com/company-beta/3004538/"><i
                                                        className="bi bi-linkedin"></i></a></li>
                                                    <li><a href="https://twitter.com/aplswebdevp"><i
                                                        className="bi bi-twitter"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 ">
                                    <div className="single-widget">

                                        <div className="footer-link">
                                            <ul>
                                                <li><Link to="/website-development" className="footer-color view_more">Website Development</Link></li>
                                                <li><Link to="/software-development" className="footer-color view_more">Software Development</Link></li>
                                                <li><Link to="/mobile-application" className="footer-color">Mobile Application Development</Link></li>
                                                <li><Link to="/customised-software-development" className="footer-color">Customised Software Development</Link></li>
                                                <li><Link to="/business-autmation" className="footer-color view_more">Business Automation</Link></li>
                                                <li><Link to="/itsupport" className="footer-color view_more">IT Support</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 ">
                                    <div className="single-widget">

                                        <div className="footer-link">
                                            <ul>
                                                <li><Link to="/data-science-and-analytics" className="footer-color view_more">Data Science and Analytics</Link></li>
                                                <li><Link to="/digital-marketing" className="footer-color view_more">Digital marketing</Link></li>
                                                <li><Link to="/automation" className="footer-color view_more">Automation and AI</Link></li>
                                                <li><Link to="/cloud" className="footer-color view_more">Cloud</Link></li>
                                                <li><Link to="/careers" className="footer-color view_more">Careers</Link></li>
                                                <li><Link to="/news" className="footer-color view_more">News</Link></li>
                                                <li><a href="/terms-and-conditions" className="footer-color view_more" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                                    <div className="single-widget">
                                        <div className="footer-title">
                                            <h3><i className="bi bi-geo-alt-fill"></i> US office</h3>
                                        </div>
                                        <div className="contact-info">
                                            <ul>
                                                <li><span>17670 NW 78th Ave STE 212, Hialeah, FL 33015
</span><br/>
                                                    <span className="pt-2"> (786) 508-2030</span>
                                                    <br/>
                                                    <span className="pt-2"><i className="fa fa-phone"></i> +1 (786) 864-0733</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 ">
                                    <div className="single-widget">
                                        <div className="footer-title">
                                            <h3><i className="bi bi-geo-alt-fill"></i> India office</h3>
                                        </div>
                                        <div className="contact-info">
                                            <ul>
                                                <li><span>Plot No - 13 , Ramchandranagar,
                                                        shahnoorwadi, Aurangabad.</span><br/>
                                                    <span className="pt-2"><i className="fa fa-envelope"></i> nb@aplswd.com</span><br/>
                                                    <span className="pt-2"><i className="fa fa-phone"></i> +91 8928948431</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
            <div className="footer-area1 hrrd-footer-area positioning pb-3">
            <div className="container">
                <div className="row">
                    <div className="col-xl-16 col-lg-6 col-md-6 col-sm-6">
                        <div className="copy-right-area1 pt-3">
                            <p className="text-white fs-14" >© All Rights Reserved 2014-2022 aPLS Web
                                Development.</p>
                        </div>
                    </div>
                    <div className="col-xl-16 col-lg-6 col-md-6 col-sm-6">
                        <div className="copy-right-area2 pt-3">
                            <p className="text-white fs-14" > Developed by <a href="https://aplswd.com/" >aPLS Web Development </a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}

export default Footer