import React from 'react'
import {Link} from "react-router-dom";

function TermsAndConditions() {
  return (
		<div >

			<div className="positioning mb-5">
				<div className="container-fluid blog">
					<div className="container">
						<div className="row align-items-center justify-content-between">
							<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
								<div className=" text-center">
									<h1 className="section-heading">Terms and Conditions</h1>
                  <p><Link to="/">Home</Link> / <Link to="/terms-and-conditions" >Terms and Conditions</Link></p>
								</div>
							</div>
							<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="space-bar"></div> */}

			<div className="blog-post-area ">
      <div className="container px-5 shadow-sm rounded-3 p-5 border-top border-light border-2 border-animate-t">


					<div className="row">

          <div className="space-y-6 text-gray-700">
          <p className='mb-3'>
            Welcome to <strong>aPLS Web Development.</strong> These Terms and Conditions ("Terms")
            govern your use of our website and services, including web
            application and software development services provided by aPLS Web
            Development. By accessing or using our services, you agree to comply
            with and be bound by these Terms. If you do not agree, please do not
            use our services.
          </p>
</div>
<div className='py-3 flex items-cente'>
          <h5 className="text-xl font-semibold text-gray-800">Definitions</h5>
          <ul className="list-disc list-inside space-y-2">
            <li><strong>Company:</strong> aPLS Web Development.</li>
            <li><strong>Client:</strong> The individual or entity utilizing the services.</li>
            <li><strong>Services:</strong> Web application development, software development, automation, AI integrations, and related services.</li>
            <li><strong>Deliverables:</strong> The final product or application developed and delivered to the Client.</li>
          </ul>
         

          </div>
          <hr />
          <div className='pb-4 pt-2 '>
          <h5 className="text-xl font-semibold text-gray-800">Scope of Services</h5>
          <p>
            aPLS Web Development provides customized web and software development
            solutions tailored to the Client's requirements. The scope, features,
            timelines, and costs will be outlined in a separate agreement.
          </p>
          
          </div>
          <hr />
          <div className='pb-4 pt-2 '>
          <h5 className="text-xl font-semibold text-gray-800">Intellectual Property Rights</h5>
          <ul className="list-disc list-inside space-y-2">
            <li>All intellectual property rights of the Deliverables shall be transferred upon full payment, unless otherwise agreed.</li>
            <li>aPLS Web Development retains the right to showcase the project in its portfolio unless agreed otherwise.</li>
          </ul>
          </div>
          <hr />
          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800"> Payment Terms</h5>
          <ul className="list-disc list-inside space-y-2">
            <li>Fees, payment schedules, and milestones will be pre-agreed.</li>
            <li>Payments are non-refundable unless otherwise stated.</li>
            <li>Late payments may result in project delays.</li>
          </ul>
          </div>
          <hr />
          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800">Client Responsibilities</h5>
          <ul className="list-disc list-inside space-y-2">
            <li>Providing clear requirements, timely feedback, and necessary content.</li>
            <li>Responsible for operational use, administration, and maintenance of Deliverables post-deployment.</li>
          </ul>
          </div>
          <hr />
          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800"> Limitation of Liability</h5>
          <p>
            aPLS Web Development shall not be liable for any damages arising out
            of the use of Deliverables. The Client assumes full responsibility
            for its use.
          </p>
          </div>
          <hr />
          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800">Disclaimer of Warranties</h5>
          <p>
            All services and Deliverables are provided "as-is" without warranties
            of any kind, including merchantability or fitness for a particular
            purpose.
          </p>
          </div>
          <hr />
          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800">Indemnification</h5>
          <p>
            The Client agrees to indemnify aPLS Web Development from any claims,
            damages, or legal issues arising from their use of Deliverables.
          </p>
          </div>
          <hr />
          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800"> Confidentiality</h5>
          <p>
            Both parties agree to maintain confidentiality regarding proprietary
            information exchanged during the project.
          </p>
          </div>
          <hr />

          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800"> Termination</h5>
          <ul className="list-disc list-inside space-y-2">
            <li>Either party may terminate the agreement with written notice if terms are breached.</li>
            <li>Upon termination, all completed work must be paid for.</li>
          </ul>
          </div>
          <hr />

          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800">Governing Law</h5>
          <p>
            These Terms shall be governed by the laws of Maharashtra, India, and
            any disputes shall be resolved in Aurangabad-MH, India.
          </p>
          </div>
          <hr />

          <div className='pb-4 pt-2'>
          <h5 className="text-xl font-semibold text-gray-800"> Changes to Terms</h5>
          <p>
            aPLS Web Development reserves the right to modify these Terms at any
            time. Continued use of our services implies acceptance of such
            changes.
          </p>
          </div>
          <hr />
          <div className='pb-4 pt-2'>
          <h5 className="text-lg font-semibold text-gray-800 text-center">
            Acceptance of Terms
          </h5>
          <p className="text-center text-gray-600">
            By engaging with our services, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and Conditions.
          </p>
        </div>
      </div>
				</div>
			</div>



		</div>
	);
}

export default TermsAndConditions